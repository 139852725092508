










































import { computed, defineComponent } from '@vue/composition-api';
import { getValidCSSUnit } from '~/constants/helpers';
import { isTabletUpEffect } from '~/effects/matchMedia';
import LazyHydrate from 'vue-lazy-hydration';
import { filterOutComponent, findComponent } from '~/selectors/selectorsHelpers';
import { getAngieUIComponent } from '~/selectors/selectorsMap';
import { IGeneralContentAttrs } from './types';

export default defineComponent({
  name: 'GeneralContent',
  inheritAttrs: false,
  components: {
    LazyHydrate,
  },
  setup(_, context) {
    const {
      height,
      tabletUpHeight,
      contentContainer: cmsArray,
      contentPosition,
      contentContainerWidth: width,
      paddingTop,
      paddingBottom,
      tabletUpPaddingTop,
      tabletUpPaddingBottom,
      paddingLeft,
      paddingRight,
      tabletUpPaddingLeft,
      tabletUpPaddingRight,
      codeId: trackingData,
      containerSize,
    } = context?.attrs as IGeneralContentAttrs;

    const isTabletUp = isTabletUpEffect();
    const getProps = (comp: any) => {
      if (comp?.component === 'Experiment') {
        return { body: comp.body, growthbook: comp.growthbook };
      }
      return getAngieUIComponent(comp?.component, comp) ?? comp;
    };

    const getMediaBackgroundComponent = computed(() => {
      const foundComponent = findComponent({
        name: 'MediaBackground',
        cmsArray,
      });
      if (!foundComponent) return null;
      return {
        ...foundComponent,
        bindings: getProps(foundComponent),
      };
    });

    return {
      containerStyles: computed(() => ({
        height: getValidCSSUnit((isTabletUp.value ? (tabletUpHeight ?? height) : height) ?? height, 'auto'),
      })),
      getMediaBackgroundComponent,
      generalContentStyles: computed(() => ({
        width: getValidCSSUnit(width),
      })),
      getPaddingClasses: computed(() => {
        const classes = [] as string[];

        if (paddingTop) classes.push(paddingTop);
        if (paddingBottom) classes.push(paddingBottom);
        if (tabletUpPaddingTop) classes.push(tabletUpPaddingTop);
        if (tabletUpPaddingBottom) classes.push(tabletUpPaddingBottom);
        if (paddingLeft) classes.push(paddingLeft);
        if (paddingRight) classes.push(paddingRight);
        if (tabletUpPaddingLeft) classes.push(tabletUpPaddingLeft);
        if (tabletUpPaddingRight) classes.push(tabletUpPaddingRight);

        return classes;
      }),
      filteredComponentList: computed(() => filterOutComponent({
        name: 'MediaBackground',
        cmsArray,
      }).map((comp) => ({ ...comp, bindings: getProps(comp) }))),
      getContentPosition: computed(() => contentPosition || ''),
      getContainerSize: computed(() => containerSize),
      trackingData,
      getMediaBackgroundClass: computed(() => (getMediaBackgroundComponent.value ? 'general-content__content-container--absolute' : '')),
    };
  },
});
